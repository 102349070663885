@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,400;9..40,500;9..40,600;9..40,700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'DM Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.1rem;
}

h2 {
  font-size: 2.2rem;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, #00f2fe 0%, #4facfe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  letter-spacing: 0.01em;
}

p {
  line-height: 1.6;
  margin-bottom: 1.2rem;
  font-weight: 400;
}

ul {
  list-style-position: inside;
  margin-left: 1rem;
}

li {
  margin: 0.5rem 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
